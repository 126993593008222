import React from "react"

export default props => (
  <div className="bg-light-grey py-4">
      <div className="container">
          <h4 className="text-center div-heading-center">What our customers are saying.</h4>
        <div className="row">
            <div className="bg-white px-2 py-4 box-shadow text-center">
            <h4 className="mb-2">{props.title}</h4>
            <img src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/five-stars-2.png" className="" />
            <p className="mb-2"><span className="bold">"</span>{props.content}<span className="bold">"</span></p>
            <p className="mb-1 text-sm">- {props.name}</p>
        </div>
        </div>
      </div>
  </div>
)