import React from "react"
import { Link } from "gatsby"
import Squeeze from "../layouts/squeeze-landing"
import Hero from "../components/homeHero"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhereToBuy from "../components/whereToBuy"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"
import CustomerTestimonial from "../components/customerTestimonial"

export default () => (
  <div>
    <SEO
      title="Panls Whiteboards For Office & Education | Magnetic Dry Erase Board"
      description="High-quality Ceramic Steel Whiteboards. Durable, Magnetic, and Glare-free. Lifetime Warranty. Dry-erase board for Office, Education, Home."
    />
    <Base>
      <Hero
        logo="https://res.cloudinary.com/iwh/image/upload/w_500,c_scale/q_auto,g_center/assets/1/7/panls_brandlogoconcept_recolor_v2w-reg.png"
        text="Innovative Collaborative Surfaces"
        image="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/overlay_panls_gray.jpg"
      />
      <CustomerTestimonial
        title="First class equipment"
        content="I purchased this board right after the pandemic started. I set it up in my home office in about 15 minutes. It was a life saver as I was able to use this daily during my Zoom calls with both employees and clients. Recently I moved it to my main office and I am able to move it around the office as we are currently utilizing different sections for our meetings in order to continue safe social distancing guidelines. It is super versatile as well as easy to clean. The markers glide so nicely on this board as you write it is amazing."        name="Chris Todd on Sep 04, 2020"
      />
      <Squeeze>
        <TextLeftImgRight
          heading="Simple Installation"
          p1="Panls dry erase boards are much lighter in weight than alternatives
          like glassboards. That means Panls whiteboards are much easier to install.
          Every Panls board includes a mounting template and detailed instructions
          for a stress-free install. A typical install requires just basic tools
          and can be completed in only three steps."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-easy-install.jpg"
          buybtn="d-none"
        />
        <ImgLeftTextRight
          heading="Easy Cleanup"
          p1="Unlike other dry erase boards, Panls boards can return to a factory-fresh
          look with just a simple wipe thanks to CeramicSteel. Need to remove something
          tougher? You can safely clean Panls whiteboards with scrubbing brushes,
          household glass cleaners, and even powerful acid-based solutions without
          damaging the dry erase surface."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/panls_erase-1.gif"
        />
        <TextLeftImgRight
          heading="Durability Above All"
          p1="Panls dry erase boards are manufactured in the USA from
          high-quality CeramicSteel, the most durable dry erase material
          available. This ultra-tough material resists damage from scratches,
          impacts, fire, vandalism, and more. It's also fully magnetic by nature
          and allows for easy cleanup, even if you need to remove permanent
          marker or paint."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-durability.png"
          buybtn="d-none"
        />
        <div className="row mb-4 mt-2 py-2 px-1 bg-light-grey border text-center">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/ribbon-icon.png"
            className="mb-1"
          />
          <h2 className="mb-1">Forever Warranty</h2>
          <p className="mx-3">
            All Panls dry erase boards come standard with our comprehensive
            Lifetime Warranty which covers your product for as long as you own
            it. Lifetime truly means for the life of the whiteboard, and we’ll
            continue to support you no matter how long your dry erase board
            lasts. We’re behind you even if your whiteboard lasts over 200
            years!
          </p>
        </div>
        <div className="row">
          <div className="twelve columns">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/warranty-slim-panls.jpg"
              className="img-fluid"
            />
          </div>
        </div>
        <h1 className="mt-3 mb-1 px-1 py-1 bg-light-grey border">
          Our Products
        </h1>
        <div className="row py-2 px-1">
          <div className="six columns">
            <img
              className="img-fluid"
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless_-_square.jpg"
            />
          </div>
          <div className="six columns">
            <h2 className="bg-green py-1 px-1 mb-1">Limitless</h2>
            <p className="mt-0">
              The Panls Limitless whiteboard is a truly limitless platform for
              group collaboration. Our most popular option, you'll find it in
              classrooms and offices of all sizes and budgets.
            </p>
            <ul className="my-1">
              <li>
                <strong>Standoff mounts</strong>
              </li>
              <li>Available in three sizes</li>
              <li>Made from durable CeramicSteel</li>
              <li>Resistant to impact, scratching and more</li>
              <li>Easy to clean with almost any cleaner</li>
              <li>Lifetime Warranty</li>
            </ul>
            <p className="section-link">
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/limitless/">
                Learn More →
              </AniLink>
            </p>
          </div>
        </div>
        <div className="row py-2 px-1">
          <div className="six columns">
            <img
              className="img-fluid"
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash_-_square.jpg"
            />
          </div>
          <div className="six columns">
            <h2 className="bg-teal py-1 px-1 mb-1">Dash</h2>
            <p className="mt-0">
              Dash is our portable whiteboard, designed to be rolled around your
              space for on-demand collaboration. Housed in a mobile stand, Dash
              makes it easy to add a portable whiteboard to your school or
              business.
            </p>
            <ul className="my-1">
              <li>
                <strong>Mobile whiteboard</strong>
              </li>
              <li>Dual-sided writing surface</li>
              <li>Made from CeramicSteel</li>
              <li>Easy cleanup with most cleaners</li>
              <li>Fully magnetic</li>
              <li>Lifetime Warranty</li>
            </ul>
            <p className="section-link">
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/dash/">
                Learn More →
              </AniLink>
            </p>
          </div>
        </div>
        <div className="row py-2 px-1">
          <div className="six columns">
            <img
              className="img-fluid"
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/rapid_-_square.jpg"
            />
          </div>
          <div className="six columns">
            <h2 className="bg-yellow py-1 px-1 mb-1">Rapid</h2>
            <p className="mt-0">
              The Panls Rapid is our flagship model, featuring a sleek frameless
              dry erase surface and easy-to-install concealed mounting hardware.
              It's a great addition to any modern meeting space or university
              classroom.
            </p>
            <ul className="my-1">
              <li>
                <strong>Concealed mounts</strong>
              </li>
              <li>Available in three sizes</li>
              <li>Made from CeramicSteel</li>
              <li>Resists virtually all types of damage</li>
              <li>Easy cleanup with almost any cleaner</li>
              <li>Lifetime Warranty</li>
            </ul>
            <p className="section-link">
              <AniLink paintDrip hex="#ea4c12" duration={0.75} to="/rapid/">
                Learn More →
              </AniLink>
            </p>
          </div>
        </div>
        <div className="row my-3">
          <div className="six columns">
            <h2 className="bg-light-grey py-2 px-2 border mb-1">
              Sustainability for a Better Planet. Made from{" "}
              <span className="text-green">66%</span> recycled material
            </h2>
            <p className="px-1 mb-1">
              At Panls, we take every step we can to ensure our impact on the
              environment is minimized, from design and manufacturing to
              delivery and product lifecycle.
            </p>

            <p className="px-1">
              In working towards this goal, we use up to 66% recycled content in
              every Panls whiteboard. In addition, all our products support the
              U.S. Green Building Council’s LEED certification system through
              our use of recycled content, low-emitting materials, and
              sustainable purchasing.
            </p>
          </div>
          <div className="six columns">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/panls-sustainability-2.jpg"
              className="img-fluid"
            />
          </div>
        </div>
        <WhereToBuy />
      </Squeeze>
    </Base>
  </div>
)
